import React from 'react';
import GaugeItem from './GaugeItem';
import TextItem from './TextItem';
import NavigateItem from './NavigateItem';
import SliderItem from './SliderItem';
import ChartItem from './ChartItem';
import LedItem from './LedItem';
const DroppedItem = ({ item }) => {
  const commonProps = {
    id: item.id,
    style: { position: 'absolute', left: item.position.x, top: item.position.y },
    settingsData: item.settings,
  };
  switch (item.type) {
    case 'GAUGE':
      return <GaugeItem {...commonProps} />;
    case 'TEXT':
      return <TextItem {...commonProps} />;
    case 'NAV':
      return <NavigateItem {...commonProps} />;
    case "SLIDER":
      return <SliderItem  {...commonProps}/>;
    case "CHART":
      return <ChartItem {...commonProps}/>;
    case "LED":
      return <LedItem {...commonProps}/>;
    default:
      return null;
  }
};

export default DroppedItem;
