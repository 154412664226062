import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { useDnd } from '../../contexts/DndContext';
import { useMqtt } from '../../contexts/MqttContext';
import { useDrag } from 'react-dnd';
import SettingsModal from '../SettingsModal';
import ChartComponent from '../ChartComponent';

const ChartItem = ({ id, style, settingsData }) => {
  const { removeDroppedItem, updateDroppedItemSettings } = useDnd();
  const { data, subscribe, unsubscribe } = useMqtt();
  const [settings, setSettings] = useState(settingsData ? settingsData : { title: "Chart", data_name: "test", minLimit:25, maxLimit:75, minValue:0,maxValue:100});

  const [{ isDragging }, drag] = useDrag({
    type: 'CHART',
    item: (monitor) => { 
        const clientOffset = monitor.getClientOffset();
        const element = monitor.getSourceClientOffset();
        const offset = {
            x: clientOffset.x - element.x,
            y: clientOffset.y - element.y,
        };
        return { id, offset, type: 'CHART', settings };
    },
    collect: (monitor) => ({
        isDragging: monitor.isDragging(),  
    })
  });

  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleMouseDown = (e) => {
    e.preventDefault();
    const elementRect = e.target.parentElement.getBoundingClientRect();

    const initialMouseX = e.clientX;
    const initialMouseY = e.clientY;
    const initialWidth = elementRect.width;
    const initialHeight = elementRect.height;

    const handleMouseMove = (e) => {
      const newWidth = Math.max(20, initialWidth + (e.clientX - initialMouseX));
      const newHeight = Math.max(20, initialHeight + (e.clientY - initialMouseY));

      setSettings(prevSettings => ({
        ...prevSettings,
        width: newWidth,
        height: newHeight
      }));
    };

    const handleMouseUp = () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp, { once: true });
  };
  const handleSettingsSave = (newSettings) => {
    setSettings(newSettings);
    updateDroppedItemSettings(id, newSettings);
  };


  return (
    <div ref={drag} style={{ ...style, opacity: isDragging ? 0.5 : 1, cursor: 'move' , width: `${settings.width}px`, height: `${settings.height}px`, position: 'relative' }}>
      <h3 style={{ textAlign: "center" }}>{settings.title}</h3>
      
      <ChartComponent data={id!=="1"?data[settings.data_name]:[{time:new Date(2024,9,3,20,20,0), value: 0},{time:new Date(2024,9,3,21,20,0), value: 100}]} settings={settings}/>
      {id !== "1" && (
        
        <>
        
        <div
        style={{
          width: '10px',
          height: '10px',
          backgroundColor: 'gray',
          position: 'absolute',
          bottom: 0,
          right: 0,
          cursor: 'nw-resize'
        }}
        onMouseDown={handleMouseDown}
      />
        <div
          style={{
            width: '10px',
            height: '10px',
            backgroundColor: 'gray',
            position: 'absolute',
            bottom: 0,
            right: 0,
            cursor: 'nw-resize'
          }}
          onMouseDown={handleMouseDown}
        />
          <label
            onClick={() => setIsModalOpen(true)}
            style={{
              position: 'absolute',
              top: 0,
              right: 24,
              padding: '2px 5px',
              fontSize: '12px',
              cursor: 'pointer',
            }}
          >
            ⚙
          </label>
          <label
            onClick={() => removeDroppedItem(id)}
            style={{
              position: 'absolute',
              top: 0,
              right: 0,
              padding: '2px 5px',
              fontSize: '12px',
              cursor: 'pointer',
              color: 'red',
            }}
          >
            X
          </label>
        </>
      )}
      <SettingsModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSave={handleSettingsSave}
        settings={settings}
        setSettings={setSettings}
      />
    </div>
  );
};

export default ChartItem;
