import React, { useContext, useState, useEffect, useRef } from 'react';
import { useDrag } from 'react-dnd';
import { useDnd } from '../../contexts/DndContext';
import { useMqtt } from '../../contexts/MqttContext';
import SettingsModal from '../SettingsModal';

const SliderItem = ({ id, style, settingsData }) => {
  const { removeDroppedItem, updateDroppedItemSettings, sendWebSocketMessage } = useDnd();
  const [settings, setSettings] = useState(settingsData ? settingsData : { title: "Slider", data_name: "sliderData",minimum_value:"0",maximum_value:"100" });
  const isSliderHovered = useRef(false);
  const {publish} = useMqtt();
  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'SLIDER',
    item: (monitor) => {
      const clientOffset = monitor.getClientOffset();
      const element = monitor.getSourceClientOffset();
      const offset = {
        x: clientOffset.x - element.x,
        y: clientOffset.y - element.y,
      };
      return { id, offset, type: 'SLIDER', settings };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    canDrag:()=>!isSliderHovered.current,
  }));

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [sliderValue, setSliderValue] = useState(50); // Default slider value set to 50

  const handleSettingsSave = (newSettings) => {
    setSettings(newSettings);
    updateDroppedItemSettings(id, newSettings);
  };

  const handleSliderChange = (event) => {
    if (id!=="1"){
      const newValue = event.target.value;
      setSliderValue(newValue);
    }
    
  };
  const handleMouseUp = (event)=>{
    publish(settings.data_name, sliderValue); // Send new slider value over WebSocket
  }
  const handleSliderHovered = ()=>{
    isSliderHovered.current=true
  }
  const handleSliderLeaved = ()=>{
    isSliderHovered.current=false
  }
  return (
    <div ref={drag} style={{ ...style, opacity: isDragging ? 0.5 : 1, cursor: 'move' }}>
      <h3 style={{ textAlign: "center" }}>{settings.title}</h3> {/* Add your title here */}
      <input
        type="range"
        min={settings.minimum_value}
        max={settings.maximum_value}
        value={sliderValue}
        onChange={handleSliderChange}
        onMouseUp={handleMouseUp}
        onMouseEnter={handleSliderHovered}
        onMouseLeave={handleSliderLeaved}
        style={{ width: '100%' }}
      />
      <div style={{ textAlign: "center", marginTop: "5px" }}>
          Valeur actuelle: {sliderValue}
      </div>
      {id !== "1" && (
        <>
          <label
            onClick={() => setIsModalOpen(true)}
            style={{
              position: 'absolute',
              top: 0,
              right: 24,
              padding: '2px 5px',
              fontSize: '12px',
              cursor: 'pointer',
            }}
          >
            ⚙
          </label>
          <label
            onClick={() => removeDroppedItem(id)}
            style={{
              position: 'absolute',
              top: 0,
              right: 0,
              padding: '2px 5px',
              fontSize: '12px',
              cursor: 'pointer',
              color: 'red',
            }}
          >
            X
          </label>
        </>
      )}
      <SettingsModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSave={handleSettingsSave}
        settings={settings}
        setSettings={setSettings}
      />
    </div>
  );
};

export default SliderItem;
