// ChartComponent.js
import React from 'react';
import { Line } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';
import 'chartjs-adapter-date-fns';
import annotationPlugin from 'chartjs-plugin-annotation';

Chart.register(...registerables);

const ChartComponent = ({ data = [],settings}) => {
  if (data.length === 0 || typeof(data) !== typeof([])) {
    return <div>No data available</div>;
  }
  const chartData = {
    datasets: [
      {
        label: 'MQTT Data',
        data: data.map((d) => ({
          x:new Date(d.time),
          y: d.value,
        })), // Ensure this is a numeric array
        borderColor: 'rgba(75,192,192,1)',
        backgroundColor: 'rgba(75,192,192,0.2)',
        fill: false,
      },
    ],
  };

  const options = {
    responsive: true,
    scales: {
      x: {
        type: 'time', // Use time scale for x-axis,
        time: {
          unit: 'minute', // Display time in minutes
          displayFormats: {
            minute: 'HH:mm', // Display time in hours and minutes
          },
        },
        Ticks: {
          source: 'auto',
          autoSkip: true,
        },
        title: {
          display: true,
          text: 'Time',
        },
      },
      y: {
        display: true,
        title: {
          display: true,
          text: 'Value',
        },
        min: parseInt(settings.minValue), // Set the minimum y-axis value
        max: parseInt(settings.maxValue), // Set the maximum y-axis value
      },
    },
    annotation: {
      annotations: [{
        type:"line",
        mode:"horizontal",
        scaleID:"y-axis-0",
        value:5,
        borderwidth:10,
        borderColor:'rgb(100,100,100)'
      }]
    }
  };

  return <Line data={chartData} options={options} />;
};

export default ChartComponent;
