import React, { createContext, useEffect, useState, useContext } from 'react';
import mqtt from 'mqtt';
const MqttContext = createContext();

export const MqttProvider = ({ children }) => {
    const [client, setClient] = useState(null);
    const [data, setData] = useState({});
    const [notifications,setNotifications]=useState({})
    useEffect(() => {
        const mqttClient = mqtt.connect('wss://api.technivor.net/ws',{
            username:"technivor",
            password:"bdzaa$",
        });

        mqttClient.on('connect', () => {
            console.log('Connected to MQTT broker');
        });

        mqttClient.on('message', (topic, message) => {
            if (topic !=="notification"){
                const topicName = topic.split("/")[0];
                const type = topic.split("/")[1];
                if (type==="NotAList"){
                    setData(prevData=>{
                        return {
                            ...prevData,
                            [topicName]:parseFloat(message.toString())
                        }
                    })
                    
                }else if(type==="IsAList"){
                    setData(prevData=>{
                        const exist = topicName in prevData;
                        var value = parseFloat(message.toString());
                        return {
                            ...prevData,
                            [topicName]:exist?[...prevData[topicName],{time:new Date(),value}]:[{time:new Date(),value}]
                        }
                    })
                }
            }else{
                const topicName = message.toString().split(" ")[0]
                if(!(topicName in notifications)){
                    setNotifications(prevNotif =>{
                        return{
                            ...prevNotif,
                            [topicName]:message.toString()
                        }
                    })
                }
            }
            
        });

        setClient(mqttClient);
        mqttClient.subscribe('#');
        return () => {
            mqttClient.end();
        };
    }, [notifications]);
    const subscribe = (topic) => {
        if (client) {
            client.subscribe(topic, (err) => {
                if (err) {
                    console.error('Subscription error:', err);
                }
            });
        }
    };

    const unsubscribe = (topic) => {
        if (client) {
            client.unsubscribe(topic, (err) => {
                if (err) {
                    console.error('Unsubscription error:', err);
                }
            });
        }
    };
    // New function to publish a message to a topic
    const publish = (topic, message) => {
        if (client) {
        client.publish(topic, message, (err) => {
            if (err) {
            console.error('Publish error:', err);
            } else {
            console.log(`Message published to topic ${topic}: ${message}`);
            }
        });
    }}
    return (
                <MqttContext.Provider value={{ data, subscribe, unsubscribe, notifications, publish }}>
                    {children}
                </MqttContext.Provider>
        
    );
};

export const useMqtt = () => {
    return useContext(MqttContext);
};
