import React, { useState } from 'react';
import { useDrag } from 'react-dnd';
import { useDnd } from '../../contexts/DndContext';
import { useMqtt } from '../../contexts/MqttContext';
import SettingsModal from '../SettingsModal';

const LedItem = ({ id, style, settingsData }) => {
  const { removeDroppedItem, updateDroppedItemSettings } = useDnd();
  const [settings, setSettings] = useState(settingsData || { title: "LED", data_name: "test", ledColorOn: "green",ledColorOff:"red", publishTopic:"",width:"20",height:"20"});
  const { data, publish} = useMqtt();
  
  
  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'LED',
    item: (monitor) => {
      const clientOffset = monitor.getClientOffset();
      const element = monitor.getSourceClientOffset();
      const offset = {
        x: clientOffset.x - element.x,
        y: clientOffset.y - element.y,
      };
      return { id, offset, type: 'LED', settings };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleSettingsSave = (newSettings) => {
    if (id !== "1" && newSettings.data_name !== settings.data_name) {
      console.log("Subscribed to", newSettings.data_name);
    }
    setSettings(newSettings);
    updateDroppedItemSettings(id, newSettings);
  };
  // Calculate LED color based on the data
  const getLedColor = () => {
    if (!(settings.data_name in data)) {
      return 'gray'; // Default color when no data
    }
    const value = data[settings.data_name];
    // Change the color logic as needed
    if (value === 1) {
      return settings.ledColorOn; // LED on
    } else {
      return settings.ledColorOff; // LED off
    } 
  };
  const handleClick = ()=>{
    if (settings.data_name in data){
        publish(settings.publishTopic, (1-data[settings.data_name]).toString())
    }
  }
  const [initialMouseX, setInitialMouseX] = useState(null);
  const [initialMouseY, setInitialMouseY] = useState(null);
  const [initialWidth, setInitialWidth] = useState(null);
  const [initialHeight, setInitialHeight] = useState(null);
  const handleMouseDown = (e) => {
    e.preventDefault();
    const elementRect = e.target.parentElement.getBoundingClientRect();
  
    setInitialMouseX(e.clientX);
    setInitialMouseY(e.clientY);
    setInitialWidth(elementRect.width);
    setInitialHeight(elementRect.height);
    
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp, { once: true });
  };
  const handleMouseMove = (e) => {
    const newWidth = Math.max(20, initialWidth + (e.clientX - initialMouseX));
    const newHeight = Math.max(20, initialHeight + (e.clientY - initialMouseY));
    
    setSettings(prevSettings => ({
      ...prevSettings,
      width: newWidth,
      height: newHeight
      
    }));
    updateDroppedItemSettings(id,settings)
  };
  const handleMouseUp = () => {
    document.removeEventListener('mousemove', handleMouseMove);
    
  };
    return (
    <div ref={drag} style={{ ...style, opacity: isDragging ? 0.5 : 1, cursor: 'move' ,width: `${settings.width}px`,height: `${settings.height}px`}}>
      <h3 style={{ textAlign: "center" }}>{settings.title}</h3>
      <div
      onClick={handleClick}
        style={{
          width:"100%",
          height:"100%",
          borderRadius: '50%',
          backgroundColor: getLedColor(),
          margin: '0 auto',
        }}
      ></div>
      
      {id !== "1" && (
        <>
        <div
          style={{
            width: '10px',
            height: '10px',
            backgroundColor: 'gray',
            cursor: 'nw-resize',
            position:'absolute',
            right:"0"
          }}
          onMouseDown={handleMouseDown}
        />
          <label
            onClick={() => setIsModalOpen(true)}
            style={{
              position: 'absolute',
              top: 0,
              right: 24,
              padding: '2px 5px',
              fontSize: '12px',
              cursor: 'pointer',
            }}
          >
            ⚙
          </label>
          <label
            onClick={() => removeDroppedItem(id)}
            style={{
              position: 'absolute',
              top: 0,
              right: 0,
              padding: '2px 5px',
              fontSize: '12px',
              cursor: 'pointer',
              color: 'red',
            }}
          >
            X
          </label>
        </>
      )}
      <SettingsModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSave={handleSettingsSave}
        settings={settings}
        setSettings={setSettings}
      />
      
    </div>
    );
};

export default LedItem;