import React, { useContext, useState, useEffect } from 'react';
import { DndProvider as BackendProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import './ItemLayout.css';
import { FaHome, FaInfoCircle, FaServicestack, FaEnvelope, FaTools } from 'react-icons/fa';
import { useDnd } from '../contexts/DndContext';
import { DndProvider } from '../contexts/DndContext';
import GaugeItem from './items/GaugeItem';
import DroppableArea from './DroppableArea';
import TextItem from './items/TextItem';
import NavigateItem from './items/NavigateItem';
import { useNavigate, useParams } from 'react-router-dom';
import ProjectAdmin from '../pages/ProjectAdmin';
import SliderItem from './items/SliderItem';
import ChartItem from './items/ChartItem';
import { useMqtt } from '../contexts/MqttContext';
import LedItem from './items/LedItem';
import axios from '../axiosConfig'; // Import your Axios instance
const ItemLayout = () => {
  const [selectedIcon, setSelectedIcon] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate();
  const {projectid, pageid}= useParams();
  //const {notifications} = useMqtt();
  const [alerts,setAlerts] = useState([]);
  useEffect(() => {
    const fetchAlerts = async () => {
      try {
        const response = await axios.get('/alerts', {withCredentials: true});
        setAlerts(response.data);
      } catch (error) {
        console.error('Error fetching projects:', error);
        // Handle error (show message, etc.)
      }
    };
    fetchAlerts();
    const intervalId = setInterval(() => {
      fetchAlerts();
    }, 5000); // Fetch alerts every 5000 milliseconds (5 seconds)

  },[]);
  const handleIconClick = (icon) => {
    setSelectedIcon(icon === selectedIcon ? null : icon); // Toggle sidebar visibility
    console.log(icon, selectedIcon)
    /*if (icon=="home"&& pageid!=="0") {
      navigate(`/projects/${projectid}/edit/0`);
    }*/
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };
  return (
    <DndProvider>
    <BackendProvider backend={HTML5Backend}>
      <div className="main-container">
        <div className="sidebar">
          <ul>
            <li className={selectedIcon === 'tools' ? 'selected' : ''}>
              <a href="#" onClick={() => handleIconClick('tools')}><FaTools /></a>
            </li>
            <li className={selectedIcon === 'home' ? 'selected' : ''}>
              <a href="#" onClick={() => handleIconClick('home')}><FaHome /></a>
            </li>
            <li className={selectedIcon === 'info' ? 'selected' : ''}>
              <a href="#" onClick={() => handleIconClick('info')}><FaInfoCircle /></a>
            </li>
            <li className={selectedIcon === 'services' ? 'selected' : ''}>
              <a href="#" onClick={() => handleIconClick('services')}><FaServicestack /></a>
            </li>
            <li className={selectedIcon === 'notifications' ? 'selected' : ''}>
              <a href="#" onClick={() => handleIconClick('notifications')}><FaEnvelope />
              {alerts.length>0 && (
                <span className="notification-badge">{alerts.length}</span>
              )}
              </a>
            </li>
          </ul>
        </div>
        {selectedIcon && (
          <div className={`second-sidebar ${selectedIcon ? 'open' : ''}`}>
            <h2>{selectedIcon.charAt(0).toUpperCase() + selectedIcon.slice(1)}</h2>
            {selectedIcon === 'tools' && (
              <div className="tools-content">
                {1===0 && (<><input
                  type="text"
                  placeholder="Search tools..."
                  value={searchQuery}
                  onChange={handleSearchChange}
                  className="search-bar"
                /></>)}
                <GaugeItem id="1" text="Gauge" />
                <TextItem id="1" text="Text" />
                <NavigateItem id="1" text="Navigate Item" />
                <SliderItem id="1" text="Slider Item" />
                <ChartItem id="1" text="Chart Item" />
                <LedItem id="1" text="Led Item"/>
              </div>
            )}<></>
            {selectedIcon ==="notifications" && (
              <div className='notifications-content'>
                <yo></yo>
                {alerts.map((alert, index) => (
                  <div className="notification-item" key={index}>
                    <div className="notification-header">
                      <strong>{alert.topic}</strong>
                    </div>
                    <div className="notification-message">
                      <p>{alert.message}</p>
                    </div>
                  </div>
                ))}
              </div>
            )}
            {selectedIcon !== 'tools' &&selectedIcon!=='notifications' && (
              <p>Content for {selectedIcon}</p>
            )}
          </div>
        )}
        {pageid === '0'?<ProjectAdmin />:<DroppableArea />}
      </div>
    </BackendProvider>
    </DndProvider>
  );
};

export default ItemLayout;
